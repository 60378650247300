var cookieBox = document.getElementById('cookieBox');
var cookieGotIt = document.getElementById('cookieGotIt');
var agreement = localStorage.getItem('cookie_agreement');
if (!agreement) {
    cookieGotIt.addEventListener('click', function(e) {
        localStorage.setItem('cookie_agreement', true);
        cookieBox.classList.add('hidden');
    });
    cookieBox.classList.remove('hidden');
}
